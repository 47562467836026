$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #272737;
$gray-900: #15151d;
$black: #000;

$primary: #00e861;
$secondary: $gray-800;

@import 'theme-base.scss';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';

$body-color: $white;
$body-bg: $gray-900;

$border-color: $primary; //rgba($white, .125)

$list-group-color: $white;

$list-group-bg: $gray-900;
$list-group-border-color: rgba($white, .125);

$list-group-hover-bg: $gray-700;

$list-group-action-color: $white;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-600;

$progress-bg: $gray-900;

$input-bg: $gray-900;
$input-focus-bg: $gray-900;
$input-focus-color: $white;
$input-color: $gray-300;

$navbar-brand-color: $white;
$navbar-dark-brand-color: $white;
$navbar-light-brand-color: $white;
$nav-pills-link-active-color: $black;

$modal-content-bg: $gray-800;

$card-bg: $gray-900;
$card-border-width: 0;

$form-check-input-bg: $gray-600;

$link-color: $body-color;
$link-decoration: none;

.invert-dark {
    filter: invert(1);
}

body::-webkit-scrollbar {
    width: 16px;
}

body::-webkit-scrollbar-track {
    background-color: $body-bg;
}

body::-webkit-scrollbar-thumb {
    background-color: $primary;
    border: 6px solid $body-bg;
    border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: darken($primary, 10%);
}

body::-webkit-scrollbar-thumb:active {
    background-color: darken($primary, 20%);
}

section:nth-child(even) {
    background-color: $gray-800;
}

@import '~bootstrap/scss/bootstrap.scss';
@import 'mobile.scss';