@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Poppins', sans-serif;
}

.App {
    flex: 1;
    display: flex;
    flex-direction: column;
}

#home canvas {
    width: 100%;
    height: 100%;
    position: absolute;
}

#canvas {
    z-index: -1;
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

footer {
    padding: 2rem 0;
}

.my-picture {
    margin: 0 auto;
    width: 20rem;
    height: 20rem;
    border-radius: 12rem;
    background-size: cover;
    background-position: center;
}

.social-links img {
    width: 48px;
    height: 48px;
    position: relative;
    top: 0;
    transition: all 0.15s ease;
}

    .social-links img:hover {
        top: -0.3rem;
        filter: drop-shadow(0 0.3rem 0.2rem #00000044);
    }

#headline {
    min-height: 100vh;
    gap: 2rem;
    justify-content: center;
}

.fw-semibold {
    font-weight: 500;
}

@media only screen and (max-width: 1000px) {
    .my-picture {
        margin: 0 auto;
        width: 12rem;
        height: 12rem;
        border-radius: 12rem;
    }
}

li .active {
    background-color: #0a0;
}

.project-card {
    width: 20rem;
    height: 20rem;
}
