@media only screen and (max-width: 1000px) {
    .desktop-visible {
        display: none !important;
        visibility: collapse !important;
        max-height: 0 !important;
        max-width: 0 !important;
    }
}

@media only screen and (min-width: 1000px) {
    .mobile-visible {
        display: none !important;
        visibility: collapse !important;
        max-height: 0 !important;
        max-width: 0 !important;
    }
}
